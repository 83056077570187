import styled from 'styled-components'
import Thephotography from '../components/Home/Thephotography';
import photographydata from '../Json/photographydata.json';
import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"


const SectionPhotographyGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function PhotographyPage() {
  return (
   
    <Layout>
      
    <Seo title="Photography" />
   
    <div className="Hero">
      <div className="HeroGroup">
      <h2>Photography</h2>
        <SectionPhotographyGroup>
          {photographydata.thephotographys.map((thephotography) => (
            <Thephotography
              title={thephotography.title}
              image={thephotography.image}
              text={thephotography.text}
              url={thephotography.url}
            />
          ))}
        </SectionPhotographyGroup>
      </div>
    </div>
    </Layout>
  );
}
